:root {
  --color-primary: #2cd5c4;
  --color-secondary: #bb29bb;
  --color-logo-black: #1b1c1a;
  --color-text: #1c1c1c;
  --color-text-faded: rgba(0, 0, 0, 0.5);
  --color-button-link: #2cd5c4;
  --color-primary-tone: #def6f4;
  --color-primary-tone-1: #6be1d5;
  --color-primary-tone-2: #2cd5c4;
  --color-primary-tone-3: #b7e1dd;
  --color-primary-tone-4: #def6f4;
  --color-primary-tone-5: #d1d3d4;
  --color-white: #fff;
  --color-black: #1c1c1c;
  --color-divider-light: rgba(0, 0, 0, 0.12);
  --color-error: rgb(215, 75, 75);
  --color-error-opacity: rgb(215, 75, 75, 0.5);
  --color-error-text: #f86262;
  --color-error-tone-1: #ffb8b8;
  --color-error-tone-2: #f07777;
  --color-grey: #bebebe;
  --color-dark-grey: #3d3e3c;
  --color-light-grey: #e8e8e8;
  --color-light-grey-2: #d8d8d8;
  --color-ultra-light-grey: #f8f8f8;
  --color-middle-grey: #8e8e8e;
  --color-middle-grey-2: #e1e1e1;
  --color-material-grey: #f0f0f0;
  --color-input: rgba(0, 0, 0, 0.06);
  --color-input-hover: #ebebeb;
  --color-purple: #bb29bb;
  --color-success-green-bg: #def9ea;
  --color-error-red-bg: #fae3e3;
  --color-error-red-bg-light: #fef7f7;
  --color-warning-orange-bg: #fdf9e6;
  --color-success-green: #24d573;
  --color-success-opacity: rgba(36, 213, 115, 0.5);
  --color-error-red: #dc4545;
  --color-warning-orange: #eec509;
  --divider-color: rgba(0, 0, 0, 0.12);
  --color-input-default: rgba(0, 0, 0, 0.06);
  --color-input-selected: rgba(0, 0, 0, 0.14);
  --color-input-border-disabled: #5c5c5c;

  --color-circular-progress: #78dbd1;

  /* switch */
  --color-switch-base-unchecked: #e4e4e4;
  --color-switch-base-checked: var(--color-success-green);
  --color-switch-track-unchecked: #1c1c1c;
  --color-switch-track-checked: #1c1c1c;
  --color-badge-grey: #555;

  --breakpoint-mobile: 900px;

  /*
    * Section Paddings
    * m = mobile;
    * d = desktop;
    * ext = external;
    * int = internal;
    * v = vertical;
    * h = horizontal;
  */
  --section-padding-default: 20px;
  --section-padding-m-ext-h: 18px;
  --section-padding-d-int-h: 30px;

  --section-padding-m-ext-v: 15px;
  --section-padding-d-ext-v: 25px;

  /*
   * Layout
   */
  --paper-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2),
    0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
  --paper-border-radius: 3px;
  --default-border-radius: 3px;

  --tab-bar-height: 90px;
  --navigation-bar-height: 64px;

  --menu-left-width: 80px;
}
